<template>
  <div class="row mt-3 mb-3 entry">
    <div class="col-md-12 entry__title">
      <div class="d-flex flex-column">
        <h3> {{ title }} </h3>
      </div>
    </div>
    <div class="col-md-12 entry__meta">
      <div class="d-flex flex-row">
        <div class="meta-item">
          <div class="meta-item__icon">
            <font-awesome-icon icon="fa-solid fa-calendar" size="md"/>
          </div>
          <span>July 18, 2022</span>
        </div>

        <div class="meta-item">
          <div class="meta-item__icon">
            <font-awesome-icon icon="fa-solid fa-user" size="sm"/>
          </div>
          <span>1</span>
        </div>
      </div>
    </div>
    <img class="col-md-12 col-lg-6 order-2 order-lg-1 entry_thumbnail" :src="require('@/assets/imgs/' + img_name + '')"
         alt="Exercise image">
    <div v-if="yt_video_id" class="col-md-12 col-lg-6 order-1 order-lg-2 entry_instruction d-flex flex-column justify-content-between">
      <div class="instruction__description mb-4">
        <h5>Instruction</h5>
        <p> {{ description }} </p>
      </div>
      <div class="instruction__video">
        <YoutubeVideo :yt_video_id="yt_video_id" alt="Exercise image"/>
      </div>
    </div>
    <div v-else class="col-md-6 order-3 coming-soon d-flex align-items-center justify-content-center">
      <p>Coming Soon</p>
    </div>
  </div>
</template>

<script>
import YoutubeVideo from "@/components/YoutubeVideo";

export default {
  name: "Entry",
  components: {
    YoutubeVideo
  },
  props: ['title', 'img_name', 'yt_video_id', 'description'],
}
</script>

<style scoped>
.entry__title h3 {
  color: #333;
  font-size: 1.5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid #eee;
}

.entry__meta {
  color: #aaa
}

.meta-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.meta-item:not(:last-child)::after {
  content: '/';
  margin: 0 15px;
}

.meta-item__icon {
  width: 1.5rem;
}

.coming-soon {
  font-size: 2rem;
}

.instruction__description h5 {
  padding-bottom: .5rem;
  border-bottom: 1px solid #eee;
}
</style>
