<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">
      <Entry title="The Messy test"
             img_name="diamond-agility.png"
             yt_video_id="Lw_GJP2Nt6o"
             description="Dribble with the ball from gate A to gate B by running with the ball straight. Once reaching gate B turn around, and dribble through the plastic cones, and finish off at gate C."/>

      <Entry title="Dribbling Champion"
             img_name="off-agility.png"
             yt_video_id="MlvmjGCaUOo"
             description="SmartGoals are blue and orange LED lighting goals that can be controlled, programmed and automated by a mobile app. As the ball passes through the gates, the sensors switch off the lights or change the colour of the lights. Professional teams such as Ajax, PSV Eindhoven, Manchester United, Schalke, Fenerbahce, Viktoria Plzen, Bate Boriszov and others are using the gates to create an interactive training environment for their players. While looking for the target, players are required to focus and look out for the next target."/>
    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "DribblingPage",
  components: {
    Entry
  }
}
</script>
