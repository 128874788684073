<template>
  <div>
    <youtube :video-id="this.yt_video_id" ref="youtube"></youtube>
  </div>
</template>

<script>
export default {
  name: "YoutubeVideo",
  props: ['yt_video_id']
}
</script>
